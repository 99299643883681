<template>
  <!-- Start Hero -->
  <section style="position:absloute"
  v-if="this.$localStorage.get(this.$store.storeName) != null"
  class="relative lg:pb-24 pb-16 md:mt-[84px] mt-[70px]">
             <div class="md:container container-fluid relative">
                 <div class="relative overflow-hidden md:rounded-md shadow
                  dark:shadow-gray-700 h-52 
                  bg-[url('https://marketetrolly.s3.eu-north-1.amazonaws.com/media/217/section/2024/03/327518311709552687.jpg')] 
                  bg-center bg-no-repeat bg-cover"
                  style="background-image:url('/theme8/images/page_banner.jpg')"
                                      ></div>
             </div><!--end container-->
 
             <div class="container relative md:mt-24 mt-16">
                 <div class="md:flex">
                     <side_bar></side_bar>
 
                     <div class="lg:w-3/4 md:w-2/3 md:px-3 mt-6 md:mt-0">
                      <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                          <h6 class="text-slate-400 mb-0">

                            <button
                            class="py-2 px-5 inline-block tracking-wide
                                            align-middle duration-500 text-base text-center
                                             bg-orange-500 text-white rounded-md w-full"
@click="
open_address
"
>
{{ $t("AddNewAddress") }}
</button>
                          </h6>
                          <div class="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-6">
                           
                            <div 
                            v-if="DBitems.length!=0"
                                      v-for="(item, index) in DBitems" :key="index"
                            class="">
                                  <div class="flex items-center mb-4 justify-between">
                                      <h5 class="text-xl font-medium">{{$t('Billing Address')}}:</h5>
                                      <a @click="getAddress(item.id)" 
                                       class="text-lg">
                                        <i v-html="edit" class="size-4"></i></a>
                                  </div>
                                  <div class="pt-4 border-t border-gray-100 dark:border-gray-700">
                                      <p class="text-lg font-medium mb-2">{{item.title}}</p>

                                      <ul class="list-none">
                                          <li class="flex">
                                              <i v-html="map_pin" class="size-4 me-2 mt-1"></i>
                                              <p class="text-slate-400">{{item.description}}</p>
                                          </li>

                                         
                                      </ul>
                                  </div>
                              </div>
                              
                          </div>
                      </div>
                  </div>
                 </div><!--end grid-->
             </div><!--end container-->

               <vodal
                class="add_address_order vodal"
                  :show="this.addAddress"
                  animation="flip"
                  @hide="close_address"
                 
                >
                  <div class="add-address" style="min-height: 350px; overflow: scroll">
                    <add-address></add-address>
                  </div>
                </vodal>



                   <vodal
                class="add_address_order vodal"
                  :show="$store.state.address_id!=null"
                  animation="flip"
                  @hide="close_address"
                >
                  <div class="add-address" style="min-height: 350px; overflow: scroll">
                    <view_address :key="$store.state.address_id" ></view_address>
                  </div>
                </vodal>
         </section><!--end section-->
         <!-- End Hero -->
 </template>

<script>
import AddAddress from "../components/AddAddress.vue";
import view_address from "../components/ViewAddress.vue";
import side_bar from "../components/sidebar_profile.vue";
import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import { HalfCircleSpinner ,HollowDotsSpinner} from "epic-spinners";

export default {
components: { Vodal,AddAddress, HollowDotsSpinner, HalfCircleSpinner,view_address,side_bar},
computed: {
  map_pin: function () {
    return feather.toSvg('map-pin')
  },
  edit: function () {
    return feather.toSvg('edit')
  },
  phone: function () {
    return feather.toSvg('phone')
  },
},

data: function() {
  return {
    loading: false,
    DBitems: null,
    address: null,
    addAddress: false,
    show2: false,
    langChange:''
  };
},
created() {
  this.$store.state.topbar='topbar_dark';
  this.$store.state.navbar='navbar_dark navbar_top';
  
  this.loading = true;
  this.axios
    .get(
      this.$store.state.base_api_url + this.$store.storeName + "/addresses",
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    )
    .then(response => {
      this.DBitems = response.data.data.addresses.addresses;
    })
    .then((this.loading = false))
    .catch(error => {
      // if (error.response.status == "401") {
      //   this.$localStorage.remove(this.$store.storeName);
      //             window.location.href="/";

      // }
    });
  // console.log("from add : "+this.$localStorage.id())
  if(this.$i18n.locale=='ar')
    {
    document.title = this.$store.state.title_ar;
    document.getElementById("description_meta").content=this.$store.state.description_ar;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
    }
    else{
      document.title = this.$store.state.title_en;
    document.getElementById("description_meta").content=this.$store.state.description_en;
    document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
    }
},
methods: {
  
  getAddress(id) {
    this.loading = true;
    this.addAddress = false;
    this.address = null;
    this.$store.state.address_id=id;
    this.show2=true;
   
  },
  updatAddress() {
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/addresses",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data.addresses.addresses;
        this.address = null;
        this.addAddress = false;
        this.$store.state.addAddress=false
        this.$store.state.address_id=null
        //console.log(this.addAddress)
        // console.log(this.DBitems);
      })
      .then((this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
                    window.location.href="/";

        }
      });
  },
  open_address()
  {
    this.addAddress=true;
    this.address=null;
    this.show2=false;
    this.$store.state.address_id=null
    this.$store.state.AddAddress=true

    console.log('add address ',this.$store.state.AddAddress);


  },
  close_address()
  {
    this.addAddress=false;
    this.address=null;
    this.show2=false;
    this.$store.state.address_id=null
    this.$store.state.AddAddress=false

  }
},
watch:
{
  langChange()
  {
    this.loading = true;
  this.axios
    .get(
      this.$store.state.base_api_url + this.$store.storeName + "/addresses",
      {
        headers: {
          "Accept-Language": this.$i18n.locale,
          Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
        }
      }
    )
    .then(response => {
      this.DBitems = response.data.data.addresses.addresses;
      // console.log(this.DBitems);
    })
    .then((this.loading = false))
    .catch(error => {
      if (error.response.status == "401") {
        this.$localStorage.remove(this.$store.storeName);
                  window.location.href="/";

      }
    });
  }
},
updated() {
  this.langChange=this.$i18n.locale
},


};
</script>