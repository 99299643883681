<template>
  <div>
    <div v-if="loading" class="d-flex flex-row justify-content-center loading_address"
      style="margin-top: 100px; margin-bottom: 200px; margin-left: auto;margin-right: auto;">
      <HalfCircleSpinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />
    </div>
    <div v-else class="card">
      <div style="
          margin-top: 30px;
          margin-bottom: 20px;
          text-align: center;
          font-size: 18px;
        ">
        <h4 style="color: var(--primary-color)">{{ address.title }}</h4>
      </div>


      <!--  -->
      <div class="grid grid-cols-1 add_address_div">
        <div class="mb-4">
          <label class="font-semibold" for="title">
            {{ $t('placeHolderTitle') }} *:</label>
          <input type="text" class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" name="name" autocomplete="false"
            :placeholder="$t('placeHolderTitle')" required maxlength="30" v-model="address.title">
        </div>
        <div class="grid lg:grid-cols-12 grid-cols-1 gap-5">
          <div class="lg:col-span-6">

            <div class="mb-4">
              <label class="font-semibold" for="city">
                {{ $t('city') }} *:</label>

              <v-select v-model="city" :reduce="(option) => option.id" :options="cities" label="city"
                :placeholder="$t('city')" class="form-select form-input mt-2 
                                        w-full py-2 px-3 h-10 bg-transparent 
                                        dark:bg-slate-900 dark:text-slate-200 
                                        rounded outline-none border border-gray-100 
                                        dark:border-gray-800 focus:ring-0" id="city"></v-select>

            </div>
          </div>
          <div class="lg:col-span-6">
            <div class="mb-4">
              <label class="font-semibold" for="region">
                {{ $t('region') }} *:</label>

              <v-select v-model="region" :reduce="(option) => option.id" :options="regions" label="city"
                :placeholder="$t('region')" class="form-select form-input mt-2 
                                        w-full py-2 px-3 h-10 bg-transparent 
                                        dark:bg-slate-900 dark:text-slate-200 
                                        rounded outline-none border border-gray-100 
                                        dark:border-gray-800 focus:ring-0" id="region">
              </v-select>

            </div>
          </div>
        </div>

        <div class="grid lg:grid-cols-12 grid-cols-1 gap-5">
          <div class="lg:col-span-6">
            <div class="mb-4">
              <label class="font-semibold" for="street">
                {{ $t('street') }} :</label>
              <input class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" autocomplete="false" required
                type="text" :placeholder="$t('street')" maxlength="255" id="street" v-model="address.street">
            </div>
          </div>
          <div class="lg:col-span-6">
            <div class="mb-4">
              <label class="font-semibold" for="building">
                {{ $t('building') }} :</label>
              <input class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" name="name" autocomplete="false"
                type="text" :placeholder="$t('building')" maxlength="255" v-model="address.building" id="building">
            </div>
          </div>
        </div>


        <div class="mb-4">
          <label class="font-semibold" for="detailes">
            {{ $t('OtherDetails') }} :</label>
          <textarea v-model="address.other_details" rows="5" cols="5" class="mt-3 w-full py-2
                                             px-3 h-10 bg-transparent 
                                             dark:bg-slate-900 dark:text-slate-200 
                                             rounded outline-none border border-gray-100 
                                             dark:border-gray-800 focus:ring-0" id="detailes">
                                    </textarea>

          <button class="py-2 px-5 inline-block 
                                 tracking-wide align-middle 
                                 duration-500 text-base text-center 
                                 bg-orange-500 text-white rounded-md w-full" @click="editAddress">{{ $t("SaveChanges")
            }}</button>

          <br>

          <button class="py-2 px-5 inline-block 
                                 tracking-wide align-middle 
                                 duration-500 text-base text-center 
                                 bg-orange-500 text-white rounded-md w-full" @click="deleteAddress"
            style="margin-top: 5px;">{{ $t("delete") }}</button>
        </div>
      </div>
      <!--  -->

    </div>
    <div v-if="Message != ''" style="
        position: fixed;
        width: 200px;
        bottom: 50px;
        left: 50px;
        border-radius: 5px;
        border: 2px solid var(--primary-color);
        text-align: center;
        padding: 5px;
        color:white;
        background-color: var(--primary-color);
      ">
      <p style="margin: 0">{{ Message }}</p>
    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { HollowDotsSpinner, HalfCircleSpinner },
  data: function () {
    return {
      loading: false,
      city: null,
      cities: [],
      region: null,
      regions: null,
      lat: 35.5,
      long: 33.5,
      Message: "",
      address: null,
      marker: { position: { lat: 300, lng: 300 } },
      center: { lat: 300, lng: 300 },
      mapOptions: {
        disableDefaultUI: true
      }
    };
  },

  created() {
    console.log('2024-10-30 view address ', this.$store.state.address_id)
    //this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/addresses/" +
        this.$store.state.address_id +
        "/edit",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        console.log('address details', response)

        console.log('address id:', this.$store.state.address_id)
        if (response.data.code == 1) {
          this.city = response.data.data.address.city;
          this.regions = response.data.data.regions;
          this.cities = response.data.data.cities;
          this.region = response.data.data.address.region;
          this.address = response.data.data.address;
        }
      })

    console.log("hiiiiiiiiiiiiiii" + this.address);


    //setTimeout(() => {
    this.loading = false;
    //},100)
  },
  methods: {
    editAddress() {
      this.axios
        .put(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/addresses/" +
          this.address.id,
          {
            title: this.address.title,
            city_id: this.city,
            region_id: this.region,
            street: this.address.street,
            building: this.address.building,
            other_details: this.address.other_details,
            lat: this.marker.position.lat,
            long: this.marker.position.lng
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          console.log(response.data.message);
          this.Message = response.data.message;
          setTimeout(() => {
            this.Message = "";
            this.$store.state.addAddress = false;
            this.$store.state.address_id = null;
          }, 1000);
          this.$destroy();
        });
    },
    deleteAddress() {
      this.loading = true;
      this.axios
        .delete(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/addresses/" +
          this.address.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            console.log(response.data.message);
            this.Message = response.data.message;
            this.$store.state.addAddress = false;
            this.$store.state.address_id = null;

            // this.$destroy();
            //this.$el.parentNode.removeChild(this.$el);
            try {
              this.$parent.$parent.updatAddress();
            } catch (Ex) {
              this.$parent.updatAddress();
            }
            this.loading = false;
            setTimeout(() => {
              this.Message = "";
              //this.$router.go(this.$router.currentRoute)
              this.$store.state.addAddress = false;
              this.$store.state.address_id = null;

            }, 1000);
          } else {
            console.log(response.data.message);
            this.Message = response.data.message;
            this.loading = false;
            setTimeout(() => {
              this.Message = "";


            }, 1000);

            this.$store.state.addAddress = false;
            this.$store.state.address_id = null;
          }
        });
      //.then(setTimeout(() => {(this.loading = false)},100));
    },

    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    }
  },

  watch: {
    city(newVal) {
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/addresses/regions/" +
          this.city,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.regions = response.data.data.regions;
          if (this.regions.find(o => o.id == this.region) == null) {
            this.region = null;
          }
        });
    }
  }
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.card {
  margin: 5%;
}

#Second {
  margin-top: 120px;
}

.card h4 {
  font-size: 15px;
  font-family: "Araboto-Medium";
  display: inline;
  color: var(--secondary-color);
}

.card h4:hover {
  color: var(--primary-color);
}

button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  color: var(--secondary-color);
}

hr {
  height: 2px;
  margin: 12px;
  color: #cccccc;
}

[dir="rtl"] button img {
  left: 15px;
  margin-top: 10px;
  position: absolute;
}

[dir="ltr"] button img {
  right: 15px;
  margin-top: 10px;
  position: absolute;
}

a {
  display: block;
  background-color: #f8f8f8;
  padding: 13px 0 17px 0;
  width: 80%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

.mapStyle {
  margin-bottom: 20px;
  border-radius: 15px;
  margin-left: 10%;
  margin-right: 10%;
  height: 315px;
  width: 80%;
}

.input-icons {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.input-icons input,
textarea.form-control {
  width: 100%;
  border-radius: 9px;
  background-color: #fff;
  margin-bottom: 10px;
  border: 2px solid #cccccc;
  font-size: 13px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  padding: 10px 20px 15px 20px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60%;

  margin: 20px 20%;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 15px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

@media (max-width: 992px) {
  a {
    width: 100%;
  }
}

@media (max-width: 772px) {
  a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>
